// Environment parameters
//
// Notes:
// - using mobile layout from printing
//
export default {
  getEnv() {
    return {
      id: 'PROD',
      apiUrl: 'wa-emat-webapp-backend-prod.azurewebsites.net', // <-- TO BE REPLACED WITH THE ACTUAL PROD SERVICE URL (if/when there is one)
      workspaceId: 'acb92667-82c2-414a-b573-6902fdb8b4ba', // <- this is the "Prod EMAT" workspace
      reports: {
        exportDashboard: {
          reportId: '5f385b38-855c-4393-bebe-241d129d1255',
        },
        grapePriceIndicators: {
          reportId: 'ae893882-5e7e-495b-81db-35f1394d0919',
        },
        vintageSurvey: {
          reportId: 'f37c689d-7be6-4ed7-b8cb-6772ba0a9738',
        },
         marketExplorer: {
          reportId: '6df682ef-e33e-4f47-a945-98308c0081f1',
        }/*,
        ma1: {
          // P02-Which similar products are exported to my export market
          reportId: 'db1aea0d-1c56-43f9-9ed0-06756fb3cd1f',
          pageId: 'ReportSection',
        },
        ma2: {
          // P01-Which export markets are receiving products similar to my product
          reportId: 'd51ca94c-c34b-423e-8069-156a6753bd62',
          pageId: 'ReportSection',
        },
        ma331: {
          // 01-Which markets drink the most wine
          reportId: 'd24cfd59-2949-48ff-9d93-5cbe1a9ff213',
          valuePageId: 'ReportSectiond86ce459343b7b1ad20b',
          volumePageId: 'ReportSection92c1f7b0eeb287108356',
        },
        ma331Mobile: {
          // 01-Which markets drink the most wine (Mobile)
          reportId: 'd537ad4f-a8cf-4746-bf36-91a59877197d',
          valuePageId: 'ReportSection24860c2d10d932e55523',
          volumePageId: 'ReportSection2c91d67d6e559462608a',
        },
        ma331Print: {
          // 01-Which markets drink the most wine (Mobile)
          reportId: 'd537ad4f-a8cf-4746-bf36-91a59877197d',
          valuePageId: 'ReportSection24860c2d10d932e55523',
          volumePageId: 'ReportSection2c91d67d6e559462608a',
        },
        ma332: {
          // 02-How does the average wine price per bottle compare across markets
          reportId: '76a692dc-0fd3-4105-b3ea-1726698e887d',
          pageId: 'ReportSection',
        },
        ma332Mobile: {
          // 02-How does the average wine price per bottle compare across markets (Mobile)
          reportId: 'c1d34c75-2243-41b4-ac7d-0f858f71f1a8',
          pageId: 'ReportSection',
        },
        ma332Print: {
          // 02-How does the average wine price per bottle compare across markets (Mobile)
          reportId: 'c1d34c75-2243-41b4-ac7d-0f858f71f1a8',
          pageId: 'ReportSection',
        },
        ma333: {
          // 03-Which markets consume the most imported wine
          reportId: '94f94f32-1413-47ae-9213-ff689421733b',
          valuePageId: 'ReportSection76fdb174b8215a2566c4',
          volumePageId: 'ReportSection65002c80008ee0027449',
        },
        ma333Mobile: {
          // 03-Which markets consume the most imported wine (Mobile)
          reportId: '68758ab2-da22-4ec0-92e0-23b9081b8239',
          valuePageId: 'ReportSectiond9ad17d3607e3a1f1ad5',
          volumePageId: 'ReportSection',
        },
        ma333Print: {
          // 03-Which markets consume the most imported wine (Mobile)
          reportId: '68758ab2-da22-4ec0-92e0-23b9081b8239',
          valuePageId: 'ReportSectiond9ad17d3607e3a1f1ad5',
          volumePageId: 'ReportSection',
        },
        ma334: {
          // 04-What is the share of on versus off premise in each market
          reportId: '59e3b4c4-c0db-4993-aaa1-351a48e3cead',
          pageId: 'ReportSection76fdb174b8215a2566c4',
        },
        ma334Mobile: {
          // 04-What is the share of on versus off premise in each market - WineConsumption - Mobile.pbi
          reportId: '693728ff-c9fd-4a63-901b-96f0719c390a',
          pageId: 'ReportSectiond0ece581a50cd4f8a559',
        },
        ma334Print: {
          // 04-What is the share of on versus off premise in each market - WineConsumption - Mobile.pbi
          reportId: '693728ff-c9fd-4a63-901b-96f0719c390a',
          pageId: 'ReportSectiond0ece581a50cd4f8a559',
        },
        ma335: {
          // 05-How much wine consumed in each market is premium
          reportId: 'ff17c891-3591-4538-8bb8-69e2f4cbafd5',
          valuePageId: 'ReportSectiond86ce459343b7b1ad20b',
          volumePageId: 'ReportSection92c1f7b0eeb287108356',
        },
        ma335Mobile: {
          // 05-How much wine consumed in each market is premium (Mobile)
          reportId: 'ee7d9e03-df68-453d-a6a8-c58173be0051',
          valuePageId: 'ReportSection',
          volumePageId: 'ReportSectioneb3d3d95cb353d58890d',
        },
        ma335Print: {
          // 05-How much wine consumed in each market is premium (Mobile)
          reportId: 'ee7d9e03-df68-453d-a6a8-c58173be0051',
          valuePageId: 'ReportSection',
          volumePageId: 'ReportSectioneb3d3d95cb353d58890d',
        },
        ma336: {
          // 06-What is wines share of the total alcohol market
          reportId: 'c8e3a0c5-75d1-46c9-9faf-bdc08baf6396',
          pageId: 'ReportSectiond86ce459343b7b1ad20b',
        },
        ma336Mobile: {
          // 06-What is wines share of the total alcohol market - ShareOfThroat - Mobile
          reportId: '1613a457-436a-4020-8fab-883fb2498fe7',
          pageId: 'ReportSectionc9c19cc32a756fe59948',
        },
        ma336Print: {
          // 06-What is wines share of the total alcohol market - ShareOfThroat - Mobile
          reportId: '1613a457-436a-4020-8fab-883fb2498fe7',
          pageId: 'ReportSectionc9c19cc32a756fe59948',
        },
        ma337: {
          // 07-Where is Australian wine exported to
          reportId: '6342a687-e1e3-4711-9f36-76719fd19f47',
          valuePageId: 'ReportSectiond86ce459343b7b1ad20b',
          volumePageId: 'ReportSection92c1f7b0eeb287108356',
        },
        ma337Mobile: {
          // 07-Where is Australian wine exported to (Mobile)
          reportId: 'fcd8acef-96c6-4730-8673-1186c419808b',
          valuePageId: 'ReportSection',
          volumePageId: 'ReportSection97560b26c0f3e4105c4a',
        },
        ma337Print: {
          // 07-Where is Australian wine exported to (Mobile)
          reportId: 'fcd8acef-96c6-4730-8673-1186c419808b',
          valuePageId: 'ReportSection',
          volumePageId: 'ReportSection97560b26c0f3e4105c4a',
        },
        ma338: {
          // 08-Where are Australian wines exported to by price point
          reportId: '49a1a334-92bf-4f1a-a996-9ee79b59ac76',
          valuePageId: 'ReportSectiond86ce459343b7b1ad20b',
          volumePageId: 'ReportSection92c1f7b0eeb287108356',
        },
        ma338Mobile: {
          // 08-Where are Australian wines exported to by price point (Mobile)
          reportId: 'a5392a05-585b-459f-bd6a-d07e234d9d18',
          valuePageId: 'ReportSectionfc8636c332d1fdbf0108',
          volumePageId: 'ReportSection',
        },
        ma338Print: {
          // 08-Where are Australian wines exported to by price point (Mobile)
          reportId: 'a5392a05-585b-459f-bd6a-d07e234d9d18',
          valuePageId: 'ReportSectionfc8636c332d1fdbf0108',
          volumePageId: 'ReportSection',
        },
        ma9: {
          // P03-Market List
          reportId: '14b1929b-f8ba-4da6-a7e5-3737591f23fb',
          pageId: 'ReportSection92c1f7b0eeb287108356',
        },
        ma10: {
          // P04-Market Summary
          reportId: 'f58e6c44-1744-4273-a995-e5ffe4bce6a6',
          pageId: 'ReportSection177b84337302b470de12',
        },
        ma11: {
          // P5-Market Comparison
          reportId: '5992b983-909e-4719-b64f-26cb14178ad6',
          pageId: 'ReportSection0ee42b2bebd18b40b13c',

        },
        ma11Labels: {
          // P5-Market Comparison - Labels
          reportId: 'afdac620-3b49-446d-a3b9-95951acc0811',
          pageId: 'ReportSection0ee42b2bebd18b40b13c',

        },
        ma11Country: {
          // P5-Market Comparison - Country
          reportId: '7fd51af5-6368-4ca9-98b8-a5ce0f9183e5',
          pageId: 'ReportSection0ee42b2bebd18b40b13c',

        },*/
      },
    };
  },
};
