import Vue from 'vue';
import Router from 'vue-router';

// Containers
import Container from '@/containers/Container.vue';

// Views
import Explore from '@/views/Explore.vue';
import Home from '@/views/Home.vue';
import MarketSummary from '@/views/MarketSummary.vue';
import MarketComparison from '@/views/MarketComparison.vue';
import MarketList from '@/views/MarketList.vue';
import NewMarkets from '@/views/NewMarkets.vue';
import NewProducts from '@/views/NewProducts.vue';
import Print from '@/views/Print.vue';
import PbiTest from '@/views/PbiTest.vue';
import FullScreenReport from '@/views/FullScreenReport.vue';

// load env and reports
import envLoader from '@/_env';

const env = envLoader.getEnv();

Vue.use(Router);

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/export-dashboard',
      name: 'Export Dashboard',
      component: FullScreenReport,
      props: {
        reportId: env.reports.exportDashboard.reportId,
      },
    },
    {
      path: '/vintage-survey',
      name: 'Vintage Survey',
      component: FullScreenReport,
      props: {
        reportId: env.reports.vintageSurvey.reportId,
      },
    },
    {
      path: '/grape-price-indicators',
      name: 'Grape price indicators',
      component: FullScreenReport,
      props: {
        reportId: env.reports.grapePriceIndicators.reportId,
      },
    },
    {
      path: '/market-explorer',
      name: 'Market Explorer',
      component: FullScreenReport,
      props: {
        reportId: env.reports.marketExplorer.reportId,
      },
    },
// {
//       path: '/',
//       redirect: '/home',
//       name: 'Container',
//       component: Container,
//       children: [
//         {
//           path: '/home',
//           name: 'Home',
//           component: Home,
//         },
//         {
//           path: '/explore/:question?',
//           name: 'Explore',
//           component: Explore,
//         },
//         {
//           path: '/market-list',
//           name: 'MarketList',
//           component: MarketList,
//         },
//         {
//           path: '/market-summary',
//           name: 'MarketSummary',
//           component: MarketSummary,
//         },
//         {
//           path: '/market-comparison',
//           name: 'MarketComparison',
//           component: MarketComparison,
//         },
//         {
//           path: '/new-markets',
//           name: 'NewMarkets',
//           component: NewMarkets,
//         },
//         {
//           path: '/new-products',
//           name: 'NewProducts',
//           component: NewProducts,
//         },
//         {
//           path: 'pbi-test',
//           name: 'PbiTest',
//           component: PbiTest,
//         },
//       ],
//     },
    {
      path: '/print/:report?',
      name: 'Print',
      component: Print,
    },
  ],
});
